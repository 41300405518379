
.our-blog {
      padding-bottom: 20px;
  }
.our-blog .main-img img{
      padding: 0px 10px;
      border-radius: 15px;
      min-height: 184px;
  }
.our-blog .info {
      box-shadow: 0px 1px 4px 0px #00000040;
      padding: 40px 20px 20px 20px;
      border-radius: 10px;
      margin-top: -25px;
      margin-bottom: 0px;
  }
.our-blog .info h3 {
      font-size: 20px;
  }
.our-blog .info span {
     color: #9E9E9E;
     padding-bottom: 15px;
     display: block;
  }
.our-blog .info a{
      color: #0081C3;
      text-decoration: none;
  }
  
  
  
  
  
  
  