.section-latestblog {
  padding: 0px 20px 40px 20px;
}
.section-latestblog .slick-next,
.section-latestblog .slick-prev {
  /* display: none !important; */
}
.section-latestblog .slick-dots li.slick-active button:before {
  color: #0081c3;
}
.section-latestblog .slick-dots li button:before {
  font-size: 13px;
}
.section-latestblog .slick-dots {
  bottom: 0px;
}
.section-latestblog .our-blog {
  margin: 12px;
}
@media screen and (max-width:767px){
  .section-latestblog {
    padding: 0px 0px 40px 0px;
  }
}